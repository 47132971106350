import React from "react"

import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"

import { Discord } from "../../Icon/Discord"
import { Sdk } from "../../Icon/Sdk"
import { Telegram } from "../../Icon/Telegram"
import { Twitter } from "../../Icon/Twitter"
import Block from "./Block"

import "./Social.css"

export default React.memo(function JoinDiscord() {
  const l = useFormatMessage()

  return (
    <div className="blog-social__content">
      <Block
        url={l("general.discord_cta_target")}
        label={
          <p>
            {l("general.join_us_on")}
            <strong>{l("general.discord")}</strong>
          </p>
        }
        icon={<Discord />}
      />
      <Block
        url={l("general.twitter_cta_target")}
        label={
          <p>
            {l("general.follow_us_on")}
            <strong>{l("general.twitter")}</strong>
          </p>
        }
        icon={<Twitter />}
      />
      <Block
        url={l("general.telegram_cta_target")}
        label={
          <p>
            {l("general.join_us_on")}
            <strong>{l("general.telegram")}</strong>
          </p>
        }
        icon={<Telegram />}
      />
      <Block
        url={l("general.sdk_cta_target")}
        label={
          <p>
            {l("general.get_started_with_our")}
            <strong>{l("general.sdk")}</strong>
          </p>
        }
        icon={<Sdk />}
      />
    </div>
  )
})
