import React from "react"

import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import "./Discord.css"

export type DiscordProps = React.SVGAttributes<SVGElement>

export const Discord = React.memo(function (props: DiscordProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="29"
      viewBox="0 0 40 29"
      className={TokenList.join(["icon-discord", props.className])}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M14.48 0c-.53.002-4.917.129-9.544 3.62 0 0-4.936 9.018-4.936 20.123 0 0 2.88 5.013 10.454 5.257 0 0 1.268-1.532 2.297-2.855-4.353-1.323-5.999-4.073-5.999-4.073s.343.244.96.592c.034 0 .069.035.137.07.103.07.206.104.309.174.857.487 1.713.87 2.502 1.183 1.405.592 3.085 1.114 5.038 1.497 2.571.488 5.587.662 8.878.035 1.61-.313 3.256-.766 4.97-1.497a19.5 19.5 0 0 0 3.942-2.054s-1.714 2.82-6.204 4.108a270.224 270.224 0 0 0 2.262 2.785C37.12 28.722 40 23.708 40 23.743c0-11.105-4.936-20.122-4.936-20.122C30.163-.104 25.467 0 25.467 0l-.48.556c5.827 1.776 8.535 4.387 8.535 4.387a28.178 28.178 0 0 0-10.317-3.307 28.558 28.558 0 0 0-6.924.07c-.206 0-.377.034-.583.069-1.2.14-4.113.557-7.78 2.193-1.268.557-2.023.975-2.023.975s2.811-2.75 8.98-4.526L14.534 0h-.054zm-.872 12.847c1.953 0 3.53 1.705 3.496 3.829 0 2.124-1.543 3.83-3.496 3.83-1.92 0-3.497-1.706-3.497-3.83s1.543-3.83 3.497-3.83zm12.51 0c1.92 0 3.496 1.705 3.496 3.829s-1.542 3.83-3.496 3.83c-1.92 0-3.496-1.706-3.496-3.83s1.542-3.83 3.496-3.83z" />
      </g>
    </svg>
  )
})
