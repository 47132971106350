import React, { useEffect } from "react"

import { useLocation } from "@reach/router"
import Head from "decentraland-gatsby/dist/components/Head/Head"
import NotFound from "decentraland-gatsby/dist/components/Layout/NotFound"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import PostCard from "../../../components/Blog/PostCard/PostCard"
import Social from "../../../components/Blog/Social/Social"
import Subscription from "../../../components/Blog/Subscription/Subscription"
import BlogNavigation from "../../../components/Layout/BlogNavigation"
import Layout from "../../../components/Layout/Layout"
import Post from "../../../components/Layout/Post"
import {
  isWebpSupported,
  useImageOptimization,
} from "../../../hooks/contentful"
import {
  getBlogCategories,
  getBlogpost,
  getBlogposts,
} from "../../../modules/contentful"
import { createBlogPostList } from "../../../modules/formats"
import { slugify } from "../../../modules/string"

import "./post.css"

export default function PostPage(props: any) {
  const l = useFormatMessage()
  const [post, postState] = useAsyncMemo(
    async () => getBlogpost(props.post),
    [props.post]
  )

  const location = useLocation()

  const [categories] = useAsyncMemo(getBlogCategories)

  const [relatedPosts, relatedPostsState] = useAsyncMemo(
    async () => {
      if (!categories || !post) {
        return false
      }
      const blogpostContent = await getBlogposts({
        offset: 0,
        limit: 4,
        categoryId: post.fields.category.sys.id,
      })
      const blogpostCurated = await createBlogPostList(
        blogpostContent,
        categories
      )

      return blogpostCurated.filter(
        (postCurated) =>
          slugify(postCurated.title) !== slugify(post.fields.title)
      )
    },
    [categories, post],
    {
      callWithTruthyDeps: true,
    }
  )

  useEffect(() => {
    if (
      postState.loaded &&
      !postState.loading &&
      post &&
      location.hash &&
      relatedPostsState.loaded
    ) {
      Promise.resolve().then(() => {
        const getMeTo = document.getElementById(location.hash.substring(1))
        getMeTo?.scrollIntoView({ behavior: "smooth" })
      })
    }
  }, [postState, post, relatedPostsState])

  if (postState.loaded && !postState.loading && !post) {
    return (
      <Layout
        {...props}
        isOverlay
        hideFooter={false}
        className={"layout__blog"}
      >
        <Container style={{ paddingTop: "75px" }}>
          <NotFound />
        </Container>
      </Layout>
    )
  }

  const image = useImageOptimization(post?.fields.image?.fields.file.url)

  const loading = !postState.loaded || postState.loading
  return (
    <Layout
      {...props}
      isFullscreen
      isOverlay
      hideFooter={false}
      className={TokenList.join(["layout__blog", loading && "loading"])}
    >
      <Head
        title={post?.fields.title || ""}
        description={post?.fields.description || ""}
        image={(isWebpSupported() && image.webp) || image.jpg || ""}
      />
      <BlogNavigation active={props.category} />

      <Post post={post} loading={loading} />

      {relatedPosts && relatedPosts.length > 0 && !relatedPostsState.loading && (
        <div className="post-related">
          <Container>
            <Title>{l("page.blog.post_related")}</Title>
            <div className="post-related__wrapper">
              {relatedPosts.slice(0, 3).map((post) => (
                <PostCard post={post} key={post.id} />
              ))}
            </div>
          </Container>
        </div>
      )}
      <Container>
        <Subscription />
        <Social />
      </Container>
    </Layout>
  )
}
