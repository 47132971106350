import React from "react"

import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import "./Telegram.css"

export type TelegramProps = React.SVGAttributes<SVGElement>

export const Telegram = React.memo(function (props: TelegramProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="31"
      viewBox="0 0 36 31"
      className={TokenList.join(["icon-telegram", props.className])}
    >
      <path
        fillRule="nonzero"
        d="M.636 14.864l8.295 3.062 3.21 10.213a.98.98 0 0 0 1.552.462l4.623-3.729a1.392 1.392 0 0 1 1.682-.046l8.34 5.988c.574.413 1.387.102 1.531-.584l6.11-29.065c.157-.75-.588-1.375-1.31-1.098L.627 13.055c-.84.32-.833 1.497.01 1.81zm10.988 1.432l16.212-9.875c.291-.177.591.213.341.442l-13.38 12.3a2.733 2.733 0 0 0-.859 1.642l-.456 3.34c-.06.446-.694.49-.818.059l-1.753-6.091c-.2-.695.092-1.437.713-1.817z"
      />
    </svg>
  )
})
