import React from "react"

import Paragraph from "decentraland-gatsby/dist/components/Text/Paragraph"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import "./Subscription.css"

export default React.memo(function Subscription() {
  const l = useFormatMessage()
  return (
    <div className={TokenList.join(["subscription-container"])}>
      <div className="subscription-container__step1">
        <div className="subscription-container__content">
          <Title>{l("page.blog.subscribe.title")}</Title>
          <Paragraph>{l("page.blog.subscribe.subtitle")}</Paragraph>
        </div>

        <iframe
          src={l("component.landing.footer.subscribe.src")}
          className="subscription-container__iframe"
          data-test-id="beehiiv-embed"
        ></iframe>
      </div>
    </div>
  )
})
