import React from "react"

import { Link } from "decentraland-gatsby/dist/plugins/intl"

import "./Block.css"

export type BlockProps = {
  url: string
  icon: React.ReactNode
  label: React.ReactNode
}

export default React.memo(function Block(props: BlockProps) {
  const { url, icon, label } = props

  return (
    <Link href={url} className="social-block__container">
      {icon}
      <span>{label}</span>
    </Link>
  )
})
